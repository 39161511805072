html {
  font-size: 85%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-dropdown .p-dropdown-items-wrapper {
  max-height: 400px !important;
  /* Change it to the height you want */
}

.matters-table .p-column-filter .p-inputtext {
  padding: 0.5rem 0.5rem !important;
}

.matters-table .p-selectbutton .p-button {
  padding: 0.5rem 0.5rem !important;
  font-size: 0.8rem !important;
}

.p-paginator {
  background-color: #f8f9fa !important;
}

.p-paginator-bottom .p-paginator-left-content {
  display: none !important;
}

@keyframes pulse {
  0% {
    outline: 1px solid transparent;
  }
  50% {
    outline: 3px solid rgba(255, 0, 0, 0.556);
  }
  100% {
    outline: 1px solid transparent;
  }
}

.pulse-once {
  animation: pulse 0.3s ease-out;
  animation-fill-mode: forwards;
}

.big-box .p-tabview-panels {
  padding: 0 !important;
}

.created-events-panel p-datatable-tbody {
  /* padding: 0; */
  background-color: var(--purple-50)
}

.deadline-table .p-datatable-wrapper {
  overflow: visible !important;
}